import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { Conversation, Message } from "../models/chat.models";
import { CommonService } from "./common.service";
import { DataService } from "./data.service";
import { SsrService } from "./ssr-service";
import { AuthService } from "./authentication/auth.service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private updateUnreadIntervalId: any;
  public unreadMessages: number = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataService: DataService,
    private commonService: CommonService,
    private ssrService: SsrService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  // Functions that starts the timer that automatically queries the database for new messages
  public setUpdateUnreadInterval() {
    // Update the unread status
    this.updateUnreadStatus();
    // And start the interval in case we have not started it yet
    if (!this.updateUnreadIntervalId) {
      const window = this.ssrService.getWindow();
      if (window) {
        this.updateUnreadIntervalId = window.setInterval(() => {
          this.updateUnreadStatus();
        }, 60000);
      }
    }
  }

  /**
   * This function disables the automatic retrieval of chat messages
   */
  public clearUpdateUnreadInterval() {
    clearInterval(this.updateUnreadIntervalId);
    this.updateUnreadIntervalId = null;
  }

  /**
   * This function update the unread status
   * Is is private so that it is only called by the setUpdateUnreadTimer function
   * It is executed once per minute whenever the timer is started
   */
  private updateUnreadStatus() {
    if (!this.authService.isLoggedIn) {
      this.clearUpdateUnreadInterval();
      return;
    }
    // Query the database for new messages and in case of a succesful response update the status and set the timer
    this.getUnreadMessageCount().subscribe({
      next: (data) => {
        this.unreadMessages = data;
      },
      error: (err) => {
        // In case we had an error we clear the interval
        this.clearUpdateUnreadInterval();
        // In case of an error stop the loop and show only one message
        this.commonService.showSnackbar(
          $localize`Something went wrong downloading your chat messages. Please refresh the page to try again or contact our support in case the problem persists. We apologise for any inconvenience.`,
          true
        );
      },
    });
  }

  // API to obtain all the current conversations
  public downloadConversations(): Observable<Conversation[]> {
    return this.http.get<Conversation[]>(
      this.dataService.backendUrl + "/chat/conversations",
      this.httpOptions
    );
  }

  /**
   * The function retrieves the messages that have been exchagned with a given user
   * Either the client id or the user id can be used. Whenever the user id is provided the id has to be of format 'user-<id>'
   * @param userId user id for which we want to see the messages
   * @returns
   */
  public downloadMessages(userId: string): Observable<Message[]> {
    return this.http
      .get<
        Message[]
      >(this.dataService.backendUrl + "/chat/messages/" + userId, this.httpOptions)
      .pipe(finalize(() => this.updateUnreadStatus()));
  }

  /**
   * This function is used to send a message to a user
   * Either the client id or the user id can be used. Whenever the user id is provided the id has to be of format 'user-<id>'
   * @param userId the user id to which we want to send the message
   * @param message the message to be send
   * @param propertyId in case the user attached a property
   * @returns
   */
  public sendMessage(
    userId: string,
    message: string,
    propertyId: string | null
  ): Observable<Message[]> {
    let data = {
      recipientId: userId,
      message: message,
      propertyId: propertyId,
    };
    // Add a random uuid as a safety check. This is done because we can use the same view then
    return this.http.post<Message[]>(
      this.dataService.backendUrl +
        "/chat/messages/12466d04-14ac-11ec-82a8-0242ac130003",
      data,
      this.httpOptions
    );
  }

  // Endpoint that verifies whether the user has unread messages
  private getUnreadMessageCount(): Observable<number> {
    return this.http.get<number>(
      this.dataService.backendUrl + "/chat/unread",
      this.httpOptions
    );
  }

  /**
   * This function is used to log the user out
   */
  public resetService() {
    this.unreadMessages = 0;
    this.clearUpdateUnreadInterval();
  }
}
